@import "../../shared/styles/_variables.sass";

.quizTimer {
    text-align: center;
    background-color: $winc-grey;
    width: 100%;
    position: relative;

    > span {
        position: absolute;
        transform: translate(-50%, -100%); } }

.progress {
    height: 24px;
    background-color: $winc-yellow;
 }    // transition: width 1s linear
