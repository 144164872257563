@import "variables";

ul {
    list-style: none;

    li {
        &::before {
            content: "\2022";
            color: $winc-main-blue;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em; }

        a {
            text-decoration: none; } } }
