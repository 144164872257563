@mixin font($font-family, $font-file) {
    @font-face {
        font-family: $font-family;
        src: url($font-file);
        font-weight: normal;
        font-style: normal; } }

@include font('Brown', '/shared/fonts/brown.otf');
@include font('Brown-Bold', '/shared/fonts/brown-bold.otf');
@include font('Roboto', '/shared/fonts/roboto.ttf');
