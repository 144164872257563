
@import "../../shared/styles/_variables.sass";

.questionCard {
    border: 1px solid $winc-grey;
    border-radius: 20px;
    padding: 20px;
    margin: 50px 20px;

    box-sizing: border-box;
    position: relative;
    background-color: white;
    min-width: 300px;

    > .indexCounter {
        position: absolute;
        left: 50%;
        top: -2em;
        transform: translate(-50%, 0);
        color: darken($winc-grey, 50%);
        font-size: 0.8em; } }

.title {
    text-align: center; }

.topImage {
    display: flex;
    justify-content: center;
    & img {
        max-width: 100%;
        max-height: 500px;
        margin-bottom: 20px; } }

.imageAnswers {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 1em;
    justify-content: center; }

.answerImage {
    flex-grow: 1;

    display: flex;
    justify-content: center;

    > img {
        align-self: center;
        max-width: 100%;
        max-height: 80px;
        order: 2; }

    > .checkmark {
        order: 1;
        margin: 0 5px; } }

.ink {
    max-width: 50px; }

.answer {
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 10px;
    border-radius: 10px;

    &:hover {
        background-color: rgba($winc-tertiary-blue, 1.5); }

    > input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .checkmark {
            background-color: $winc-yellow; } } }

.checkmark {
    flex-shrink: 0;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    border: 2px solid currentColor;
    margin-right: 20px;
    transform: translateY(-0.05em);
    background: white; }

input:checked ~ .checkmark:after {
    display: block; }

.buttonRow {
    display: flex;
    justify-content: center;
    gap: 2px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);

    button {
        display: flex;
        justify-content: center;

        &:disabled {
            background-color: lighten($winc-yellow, 25%); } }

    .buttonLeft {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        img {
            transform: rotate(180deg); } }

    .buttonRight {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; } }
