// @import "styles/_fonts.sass"
// @import "styles/_variables.sass"
@import "shared/styles/winc-base.sass";

// h1
//   color: $winc-yellow

.App {
    display: flex;
    height: 100vh;

    // min-width: 400px
    // justify-content: center
    // align-items: stretch
    // background: $winc-main-blue
    flex-direction: column; }

.App {


    img {
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none; } }

.content {
    flex: 1;
    display: flex;

    justify-content: center;
    align-items: center;
    flex-direction: column;

    &> * {
        max-width: 800px; } }

.hide {
    display: none !important; }
