$winc-deep-blue: #1D334A;
$winc-main-blue: #4F8BC9;
$winc-secondary-blue: #9EC2E5;
$winc-tertiary-blue: #D4E7FA;
$winc-yellow: #FFB212;
$winc-grey: #E8EBED;

$title-font: 'Brown-Bold', 'sans-serif';
$header-font: 'Brown', 'sans-serif';
$text-font: 'Roboto', 'sans-serif';

$mobile-breakpoint: 800px;
