@import "styles/_variables.sass";

.header {
    background-color: $winc-tertiary-blue;
    font-size: 13px;
    // width: calc(100% - 100px)
    padding: 0 10px;
    // min-height: 48px
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    a {
        text-decoration: none; } }

.logoContainer {
    display: flex;
    align-items: center;
    gap: 20px;

    .title {
        font-size: 18px;
        font-family: $title-font;
        color: $winc-main-blue; }

    .logo {
        max-height: 40px;
        padding: 5px 0; } }

.contacts {
    // background-color: rgba(purple, 0.2)
    // align-self: flex-end
    display: flex;
    gap: 20px;
    // justify-content: space-around

    img {
        margin-right: 5px;
        height: 16px; }

    div {
        display: flex; }

    a:hover {
        color: $winc-yellow; } }

.selectable {
    -webkit-touch-callout: text;
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text; }

.selectableAll {
    -webkit-touch-callout: all;
    -webkit-user-select: all;
    -khtml-user-select: all;
    -moz-user-select: all;
    -ms-user-select: all;
    user-select: all; }

@media screen and (max-width: $mobile-breakpoint) {
    .header {
        // padding: 0 5px
 }        // width: calc(100% - 5px)

    .contacts {
        // width: 100%
        flex-direction: column;
        align-items: flex-end;
        gap: 5px;
        padding: 5px 0;
        justify-content: center;
        flex-shrink: 0;

        img {
            order: 2;
            margin-left: 5px;
            margin-right: 0; } }

    .title {
        display: none; } }
