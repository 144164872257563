@import "variables";

body {
    color: $winc-deep-blue;
    font-family: $text-font;
    font-size: 18px; }

h1, h2, h3, h4, h5, h6 {
    color: $winc-deep-blue;
    font-family: $header-font; }

a {
    color: $winc-deep-blue;

    &:hover {
        color: $winc-main-blue; } }

section {
    header {
        font-family: $title-font;
        font-size: 4em;

        &::before {
            content: "[";
            font-size: 1.5em;
            color: $winc-yellow;
            font-weight: bolder; }
        &::after {
            content: "]";
            font-size: 1.5em;
            color: $winc-yellow;
            font-weight: bolder; } } }

code {
    font-size: 15px; }
