@import "variables";

table {
    width: 100%;
    border-collapse: collapse;

    th {
        text-align: left;
        font-size: 1.2em;
        padding-top: 0.8em;
        padding-bottom: 0.8em;
        padding-left: 0.2em;
        text-transform: capitalize;
        border-bottom: 1px solid $winc-deep-blue; }

    td {
        text-align: left;
        padding-top: 0.4em;
        padding-bottom: 0.4em; }

    tbody {
        tr {
            &:hover {
                background-color: $winc-tertiary-blue; } } } }
