.welcome {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: 20px;
    line-height: 1.4em;

    img {
        max-height: 200px; } }
