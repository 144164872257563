$fade-duration: 500ms;

.fade-appear {
    opacity: 0;
    transform: scale(0.9);

    &-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity $fade-duration, transform $fade-duration; } }

.fade-enter {
    opacity: 0;
    transform: scale(0.9);

    &-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity $fade-duration, transform $fade-duration; } }

.fade-exit {
    opacity: 1;

    &-active {
        opacity: 0;
        transform: scale(0.9);
        transition: opacity $fade-duration, transform $fade-duration; } }
