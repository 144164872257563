@import "variables";

button {
    background-color: $winc-yellow;
    color: $winc-deep-blue;
    font-weight: bolder;
    font-size: 0.9em;
    border: none;
    border-radius: 20px;
    padding-left: 2.2em;
    padding-right: 2.2em;
    padding-top: 0.7em;
    padding-bottom: 0.7em;
    cursor: pointer;

    &:hover {
        background-color: lighten($winc-yellow, 10%); } }

label {
    font-size: 1em;
    font-weight: bold;
    padding-right: 1.5em; }

input {
    border-radius: 4px;
    border-color: $winc-yellow;
    border-style: solid;
    padding: 0.5em;
    color: $winc-deep-blue; }
